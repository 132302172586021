import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const HyperReal = () => {
  // You can adjust these styles or place them in your CSS file

  return (
    <div className="gradient-bg py-10">
      <div className="flex flex-col items-center justify-center px-2 container mx-auto">
        <Header />

        {/* Image and Video Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0">
          {/* Image 1 */}
          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/23720be5-5692-404a-8bf6-13188cf9c898%20(1)%20(1).jpg"
            alt="Placeholder"
            className="w-full h-auto rounded-lg object-cover"
          />
          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/square_cropped_image%20(2).jpg"
            alt="Placeholder"
            className="w-full h-auto rounded-lg object-cover md:-rotate-6"
          />

          {/* Video 1 */}

          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/mrrobot.jpg"
            alt="Placeholder Video"
            className="w-full h-auto rounded-lg md:rotate-6"
          />

          {/* Image 2 */}
          <video
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/resized_video.mp4"
            alt="Hyperreal Avatar"
            className="w-full h-auto rounded-lg object-cover md:rotate-6"
            controls={false}
            loop={true}
            muted={true}
            autoPlay={true}
          />

          {/* MainCard Section */}
          <div className="flex flex-row items-center text-white font-bold justify-center p-4 glass z-30 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:shadow-purple-500/50">
            <div className="rounded-lg p-4 text-center ">
              <p className="text-3xl">AvatarFun Pro</p>
              <ul className="list-none font-medium py-2 ">
                <li>
                  <span className="font-bold">100</span> HyperReal Avatars
                </li>
                <li>
                  <span className="font-bold">10</span> HyperReal Boomerang
                  Videos
                </li>
                <li>
                  <span className="font-bold">1000</span> Stylized Avatars!
                </li>
              </ul>
              <button className="cta-button">
                <a href="https://avatarfun.lemonsqueezy.com/checkout/buy/1277e6c3-fc90-4801-a8f3-9cf51e3994d6">
                  Get for $9.99
                </a>
              </button>
            </div>
          </div>

          <video
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/final-bb.mp4"
            alt="Hyperreal Avatar"
            className="w-full h-auto rounded-lg object-cover  md:-rotate-6"
            controls={false}
            loop={true}
            muted={true}
            autoPlay={true}
          />

          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/gone-girl.jpg"
            alt="Placeholder Video"
            className="w-full h-auto rounded-lg"
          />
          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/merged_new_image.png"
            alt="Placeholder Video"
            className="w-full h-auto rounded-lg md:rotate-6"
          />
          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/f8ec7166-526b-4c1a-892e-f2794825771a%20(1)%20(1).jpg"
            alt="Placeholder Video"
            className="w-full h-auto rounded-lg"
          />
          <img
            src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/b713a1d0-83d7-4a2a-9c4a-23e4fe560757%20(1).jpg"
            alt="Placeholder Video"
            className="w-full h-auto rounded-lg  md:-rotate-6"
          />
        </div>
        <footer className=" text-zinc-300  mx-auto py-2 mt-10 flex text-center  ">
          <a
            href="https://twitter.com/palmlabshq"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>

          <p className=" mx-2">|</p>
          <Link to="/terms">Terms </Link>
          <p className=" mx-2">|</p>
          <Link to="/privacy-policy">Privacy policy</Link>
        </footer>
      </div>
    </div>
  );
};

export default HyperReal;
