/* eslint-disable no-useless-escape */
import React, { useState, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import OpenAI from 'openai';
import loader from '../assets/loader.png';
import axios from 'axios';
import Popup from './Popup.jsx';
// Supabase and OpenAI initialization (assuming this is done outside or imported)
const supabaseUrl = process.env.REACT_APP_SUPABASE_BACKUP_URL; // Replace with your Supabase project URL

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_BACKUP_URL,
  process.env.REACT_APP_SUPABASE_BACKUP_ANON_KEY
);
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

function ImageMaker() {
  // States and existing handlers remain the same
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [dalleImageUrl, setDalleImageUrl] = useState('');
  const [name, setName] = useState('');
  const [theme, setTheme] = useState('normal');
  const [isUploadingImageLoading, setUploadingImageLoading] = useState(false);
  const [isGeneratedImageLoading, setGeneratedImageLoading] = useState(false);
  const [artStyle, setArtStyle] = useState('funkopop'); // Default to 'pixar'
  const [location, setLocation] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  // Handler for file selection
  const handleFileChange = useCallback(async event => {
    const file = event.target.files[0];
    if (file) {
      // Check for .heic file type
      // List of supported formats
      const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

      // Check if the file format is supported
      if (!supportedFormats.includes(file.type)) {
        alert(
          'Unsupported file format. Please upload PNG, JPEG, or JPG files.'
        );
        return;
      }

      setLoading(true);

      // Encode the file name to ensure it's safe for URLs
      const encodedFileName = encodeURIComponent(file.name);
      // Trim the encoded file name to 20 characters from the start
      // Generate a random number to append to the file name
      const randomNumber = Math.floor(Math.random() * 10000);
      const trimmedFileName = `${randomNumber}_${encodedFileName.slice(-20)}`;
      // Upload the image to Supabase Storage
      const { data, error } = await supabase.storage
        .from('8bitme') // Replace 'avatars' with your actual bucket name
        .upload(`public/${trimmedFileName}`, file, {
          cacheControl: '3600',
          upsert: false,
        });

      if (error) {
        console.error('Upload error:', error.message);
        return;
      }

      // Construct the URL for the uploaded image
      const imageUrl = `${supabaseUrl}/storage/v1/object/public/8bitme/${data.path}`;
      setUploadedImageUrl(imageUrl);
      setLoading(false);
      setUploadingImageLoading(true);
    }
  }, []);

  async function createImageFromDescription(imageUrl) {
    try {
      let visionResponse;
      if (artStyle === 'gta') {
        visionResponse = await openai.chat.completions.create({
          model: 'gpt-4-vision-preview',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: 'Heres my own Photo , Using this , I wanna create my 3d avatar image using Dalle , for  fun . Your task is to use my photo and create the expected prompt that I will later pass to dalle . Look for key attributes from my photo and follow the example prompts to give me the output.  \n\nExample outputs - \n"Create a three-dimensional image of a South Asian man with medium balding  hair and silver glasses. handsome. Slightly overweight . Around 45 years age . Warm Smile . He\'s wearing a green jacket and has a confident demeanor with a slight smirk. Place him in front of a city skyline at sunset to capture a vibrant and dynamic urban environment with palm trees , police lights and helicopters. In a style reminiscent of the Grand Theft Auto (GTA) .  Leaning over a super car . The image should have realistic 3D textures, lighting, and shadows to provide depth and an engaging atmosphere."\n\n"Create a three-dimensional image of a man with light skin tone, wearing a small beanie and sporting a short beard. He has a smiling expression and is dressed in a bomber jacket. Oval Face Shape . Place him in front of a city skyline at sunset to capture a vibrant and dynamic urban environment with palm trees , police lights and helicopters. In a style reminiscent of the Grand Theft Auto (GTA) . The image should have realistic 3D textures, lighting, and shadows to provide depth and an engaging atmosphere."\n\nCreate a three-dimensional image of a red-haired woman wearing a straw hat, holding a coffee cup, dressed in a denim overshirt and a white t-shirt. She is standing in front of a city skyline at sunset to capture a vibrant and dynamic urban environment with palm trees , police lights and helicopters.Cool Vibe. Leaning next to a vintage lambhorghini . In a style reminiscent of the Grand Theft Auto (GTA) . The image should have realistic 3D textures, lighting, and shadows to provide depth and an engaging atmosphere.\n\nOutput -',
                },
                {
                  type: 'image_url',
                  image_url: {
                    url: imageUrl,
                    detail: 'low',
                  },
                },
              ],
            },
          ],
          max_tokens: 500,
        });
      } else if (artStyle === 'pixar') {
        visionResponse = await openai.chat.completions.create({
          model: 'gpt-4-vision-preview',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: `Heres my an ai generated Photo, the photo is not real and the person in this is fictional . Using this, I wanna create its Disney Pixar type playful avatar image using Dalle, for fun. Your task is to use this photo and create the expected detailed prompt that I will later pass to dalle. Look for key attributes from the photo and follow the example prompts to give me the output. I also want a name to be overlayed on the avatar, so put the name in prompt too,  name is ${name}.\n\nExample outputs -\n\nA Disney Pixar 3D style  art of a woman around 26 years old, wearing a red saree and posing with a peace sign. It should look like a disney movie poster. She has medium-sized black hair with a middle part. The background is a vibrant Diwali scene, with festive lights and decorations. The word 'Ayesha' is prominently displayed in front of her, in a Disney-themed font, ensuring it is clearly visible and not cut off.playful disney style art. animated movie style art .\n\nA Disney Pixar 3D style  art of a 35-year-old Black man wearing a flannel shirt, standing on a beach. The background features a beach scene with sand, sea, and sky. The word 'Andre' is prominently displayed in front of him, in a Disney-themed font, ensuring it is clearly visible and not cut off. playful disney style art.animated movie style art .\"\n\n\nOutput 2 - \n\nCreate a three-dimensional image of a South Asian man with medium balding  hair and silver glasses. handsome. Slightly overweight . Around 45 years age . Warm Smile . \n\nCreate an anime figurine of a South Asian man with medium balding  hair and silver glasses. handsome. Slightly overweight . Around 45 years age . Warm Smile . They are wearing a green t-shirt . The figurine is displayed inside a box which has ${name} written and a rose logo, allowing visibility of the figure. The overall design should showcase vibrant colors, detailed typography, and realistic 3D render of the anime figure\n`,
                },
                {
                  type: 'image_url',
                  image_url: {
                    url: imageUrl,
                    detail: 'low',
                  },
                },
              ],
            },
          ],
          max_tokens: 500,
        });
      } else if (artStyle === 'funkopop') {
        visionResponse = await openai.chat.completions.create({
          model: 'gpt-4-vision-preview',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: `Using this create my funkopop figurine image prompt for Dalle  . Your task is to use this photo and create the expected prompt that will later be passed to dalle . Look for key attributes from photo and follow the example prompts to give the output .\n\nAnime figurine of  < physical description of the character in photo, eye color, hairstyle, outfit, accessories>. The figurine is displayed inside a box with ${name} and logo for the box, allowing visibility of the figure, typography, 3D render\n\nFor example, if photo shows short brown hair, blue eyes, wearing a blue shirt and a watch, and you want the box to say \"Legendary Collector's Edition\" with a dragon logo, the prompt would be:\n\nOutput -\n\"Create an anime figurine of a medium skin tone individual with blue eyes and short brown hair. They are wearing a blue shirt and a silver watch. The figurine is displayed inside a box which has ${name} written and a fiery dragon logo, allowing visibility of the figure. The overall design should showcase vibrant colors, detailed typography, and realistic 3D render of the anime figure.\"\n\n Output 2 if the photo is of a panda cartoon -\n\"Create an anime figurine of a panda with red eyes and bald hair. They are wearing a green hoodie and a silver watch. The figurine is displayed inside a box which has ${name} written and a rose logo, allowing visibility of the figure. The overall design should showcase vibrant colors, detailed typography, and realistic 3D render of the anime figure.\"\n\n`,
                },
                {
                  type: 'image_url',
                  image_url: {
                    url: imageUrl,
                    detail: 'high',
                  },
                },
              ],
            },
          ],
          max_tokens: 500,
        });
      } else {
        visionResponse = await openai.chat.completions.create({
          model: 'gpt-4-vision-preview',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: "Heres my Photo ,Provide a description my photo in the attached image , I am making a avatar from Dalle using my photo ,  focus on my photo's key attributes. Details should include Gender, Ethnicity, Skin Tone, Pose if any and prominent Facial Features. Describe Hair color and style, Top Clothing with color, and list any significant Accessories like wearing glasses or watches. Example: Gender: Male, Ethnicity: Hispanic, Skin Tone: Medium, Hair: Curly dark brown, Top: Navy blue polo shirt, Accessories: None. Gender: Female, Ethnicity: African, Skin Tone: Dark, Hair: Long black, Top: Green blouse, Accessories: Gold earrings. Gender: Non-binary, Ethnicity: Asian, Skin Tone: Light, Hair: Short purple, Top: Grey hoodie, Accessories: Round glasses. Expression: Smirking",
                },
                {
                  type: 'image_url',
                  image_url: {
                    url: imageUrl,
                    detail: 'low',
                  },
                },
              ],
            },
          ],
          max_tokens: 500,
        });
      }

      const content = visionResponse.choices[0].message.content;

      // Logic to construct a DALL-E prompt would go here

      let dallePrompt;
      switch (artStyle) {
        case '8bit':
          dallePrompt = buildDalle8bitPrompt(content);
          break;
        case 'anime':
          dallePrompt = buildDalleAnimePrompt(content);
          break;
        case 'pixar':
          dallePrompt = buildDirectPrompt(content);
          break;
        case 'gta':
          dallePrompt = buildDirectPrompt(content);
          break;
        case 'funkopop':
          dallePrompt = buildDirectPrompt(content);
          break;
        case 'ghibli':
          dallePrompt = buildDirectPrompt(content);
          break;
        default:
          dallePrompt = buildDallePixarPrompt(content);
      }

      const generateImage = async (prompt, name, ogImageUrl) => {
        try {
          const response = await axios.post(
            // 'http://127.0.0.1:8000/generate-image/',
            'https://avatarfun-imagemaker-service.onrender.com/generate-image',
            {
              prompt: prompt,
              name: name,
              ogimage_url: ogImageUrl,
              isGta: artStyle === 'gta',
              location: location.length > 0 ? location : '',
            }
          );

          return response.data;
          // Process the response as needed
        } catch (error) {
          handleShowPopup();
          // alert(
          //   'Failed to create image from description - try again/a new photo'
          // );
        }
      };

      // Example usage
      try {
        const response = await generateImage(
          dallePrompt,
          name,
          uploadedImageUrl
        );

        if (response) {
          return response.url;
          // Process the response as needed
        } else {
          console.log('Error occurred in generateImage function');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } catch (error) {
      console.error('Error:', error);
      handleShowPopup();
      throw new Error('Failed to create image from description');
    }
  }

  // Placeholder function for building the DALL-E prompt.
  function buildDalle8bitPrompt(description) {
    if (theme === 'normal') {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: Create an 8-bit avatar of a character named ${name} . 8bit art depicts ${description}.  The name ${name} is displayed at the bottom of the image in an 8-bit style font. The overall color palette should be reminiscent of classic 8-bit video games. ${name} written in a large, bold, and stylized font in front of the character. 
      `;
    } else {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: Create an 8-bit avatar of a character named ${name} . 8bit art depicts ${description}. The background features a ${theme} theme also in 8-bit design. The name ${name} is displayed at the bottom of the image in an 8-bit ${theme} style font. The overall color palette should be reminiscent of classic 8-bit video games. ${name} written in a large, bold, and stylized font in front of the character. it should reflect the ${theme} aesthetic`;
    }
  }
  function buildDallePixarPrompt(description) {
    // return `
    // I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A Disney Pixar movie poster art , name ${name} should be written on top. The top 1/4 of the poster should exclusively have the movie name ${name} written in a large, bold, and stylized font, capturing the essence of a Pixar animation. The bottom 3/4 of the poster depicts ${description} . The background in this section is vibrant, whimsical, and enchanting, suggesting an adventurous and heartwarming story.${name} written in a large, bold, and stylized font in front of the character`;

    if (theme === 'normal') {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A Disney Pixar movie style art , name ${name} should be written on top. The top 1/4 of the image should exclusively have the movie name ${name} written in a large, bold, and stylized font, capturing the essence of a Pixar animation. The bottom 3/4 of the image depicts ${description} . The background in this section is vibrant, whimsical, and enchanting, suggesting an adventurous and heartwarming story.${name} written in a large, bold, and stylized font at top of the character. high detailed pixar aesthetic `;
    } else {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A Disney Pixar movie style art , name ${name} should be written on top. The top 1/4 of the image should exclusively have the movie name ${name} written in a large, bold, and stylized font, capturing the essence of a Pixar animation. The bottom 3/4 of the image depicts ${description} . The background in this section is vibrant, whimsical, and enchanting, suggesting an adventurous and heartwarming story.${name} written in a large, bold, and stylized font at top of the character. it should reflect the ${theme} Pixar aesthetic`;
    }
  }
  function buildDalleAnimePrompt(description) {
    if (theme === 'normal') {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A 90's anime style poster, with the name ${name} displayed prominently at the top. The top 1/4 of the poster should exclusively feature the name ${name} in a large, bold, and stylized font that captures the essence of 90's anime aesthetics. The bottom 3/4 of the poster depicts ${description}. The background in this section should be dynamic and detailed, embodying the distinctive visual style of 90's anime. ${name} written in a large, bold, and stylized font in front of the character. `;
    } else {
      return `
      I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A 90's anime style poster, with the name ${name} displayed prominently at the top. The top 1/4 of the poster should exclusively feature the name ${name} in a large, bold, and stylized font that captures the essence of 90's anime aesthetics. The bottom 3/4 of the poster depicts ${description}. The background in this section should be dynamic and detailed, embodying the distinctive visual style of 90's anime. ${name} written in a large, bold, and stylized font in front of the character. it should reflect the reflecting the ${theme} 90's anime aesthetic.`;
    }
  }

  function buildDirectPrompt(description) {
    return `I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS:: ${description}`;
  }

  // function buildDalleAnimePrompt(description) {
  //   if (theme === 'normal') {
  //     return `
  //     I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: Create a painting in the style of Giotto di Bondone, resembling medieval art. name ${name}  should be written at the top in an elegant, classic script, reflecting medieval calligraphy. This title occupies the top quarter of the art, designed with ornate detail typical of Giotto's work. The bottom three quarters should depict ${description}, consistent with Giotto's style. The background should have rich, detailed landscapes and architectural elements, conveying a sense of medieval artistic style. The composition should mirror the storytelling and artistic elements characteristic of Giotto di Bondone's paintings.
  //     `;
  //   } else {
  //     return `
  //     I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS: A 90's anime style poster, with the name ${name} displayed prominently at the top. The top 1/4 of the poster should exclusively feature the name ${name} in a large, bold, and stylized font that captures the essence of 90's anime aesthetics. The bottom 3/4 of the poster depicts ${description}. The background in this section should be dynamic and detailed, embodying the distinctive visual style of 90's anime. ${name} written in a large, bold, and stylized font in front of the character. it should reflect the reflecting the ${theme} 90's anime aesthetic.`;
  //   }
  // }

  const generate = async () => {
    if (!uploadedImageUrl) {
      alert('Please upload an image first.');
      return;
    }
    if (!name) {
      alert("Please enter your avatar's name");
      return;
    }
    setLoading(true);
    setGenerating(true);

    logImageProcess(uploadedImageUrl, 'START', 'success');
    try {
      const dalleUrl = await createImageFromDescription(uploadedImageUrl);

      // Immediately display the DALL-E generated image
      setDalleImageUrl(dalleUrl);
      logImageProcess(uploadedImageUrl, dalleUrl, 'success');
    } catch (error) {
      console.error('Error:', error);
      logImageProcess(uploadedImageUrl, '-', 'failure');
    } finally {
      setGenerating(false);
      setLoading(false);
      setGeneratedImageLoading(true);
    }
  };

  // This function logs the process to Supabase
  async function logImageProcess(originalUrl, dalleUrl, status) {
    // Construct the record to be inserted

    const {
      data: { user },
    } = await supabase.auth.getUser(); // const user = supabase.auth.getUser()

    const record = {
      original_url: originalUrl,
      dalle_url: dalleUrl,
      status: status,
      created_at: new Date().toISOString(),
      personname: name,
      user_id: user ? user.id : null,
    };

    try {
      // Use Supabase client to insert the record
      const { error } = await supabase.from('image_logs').insert([record]);

      if (error) throw error;

      // Handle the response data if necessary
    } catch (error) {
      // Handle any errors in logging
      console.error('Failed to log image process:', error);
    }
  }

  const handleShowPopup = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="  rounded-lg p-4 mx-2 text-center">
      {' '}
      {/* <div className=" min-h-full glass rounded text-center container py-10 text-black relative"> */}
      {showPopup && (
        <Popup
          message={
            'Your photo may contain content that is not allowed by our safety system. , please upload another '
          }
          onClose={handleClosePopup}
        />
      )}
      {!uploadedImageUrl && !loading && (
        <>
          <button
            className=" cta-button text-2xl font-bold flex items-center justify-center mx-auto "
            onClick={() => document.getElementById('imageUpload').click()}
          >
            Upload your selfie!
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                fillRule="evenodd"
                d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <input
            type="file"
            id="imageUpload"
            className="hidden"
            onChange={handleFileChange}
          />
        </>
      )}
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="Loading"
            className="h-24 animate-spin" // Tailwind classes for size and spin animation
          />
          <p className="mt-2 text-white font-bold text-sm">
            {generating ? 'Generating' : 'Loading '}... usually takes 30-45 secs
          </p>
        </div>
      )}
      {uploadedImageUrl && !dalleImageUrl && !loading && (
        <>
          {isUploadingImageLoading && (
            <div className="text-white text-center font-bold text-lg animate-pulse">
              Loading Image...
            </div>
          )}

          <img
            src={uploadedImageUrl}
            alt="Uploaded"
            className="mx-auto w-full max-w-xs rounded"
            onLoad={() => setUploadingImageLoading(false)}
            onError={() => setUploadingImageLoading(false)}
            style={{ display: isUploadingImageLoading ? 'none' : 'block' }}
          />
          <button
            className=" bg-slate-800 text-sm text-white p-1 rounded mt-8 flex items-center justify-center mx-auto  "
            onClick={() => window.location.reload()}
          >
            Upload another
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                fillRule="evenodd"
                d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 013.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 10-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 00-4.392-4.392 49.422 49.422 0 00-7.436 0A4.756 4.756 0 003.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 101.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 013.01-3.01c1.19-.09 2.392-.135 3.605-.135zm-6.97 6.22a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 004.392 4.392 49.413 49.413 0 007.436 0 4.756 4.756 0 004.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 00-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 01-3.01 3.01 47.953 47.953 0 01-7.21 0 3.256 3.256 0 01-3.01-3.01 47.759 47.759 0 01-.1-1.759L6.97 15.53a.75.75 0 001.06-1.06l-3-3z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={e => setName(e.target.value)}
            className="text-lg text-center w-full p-2 my-2"
          />
          <div className="py-2">
            <select
              value={artStyle}
              onChange={e => setArtStyle(e.target.value)}
              className="text-lg text-center w-full p-2 my-2"
            >
              <option value="pixar">Pixar</option>
              <option value="gta">GTA</option>
              <option value="funkopop">Funko Pop</option>
              {/* <option value="ghibli">Ghibli</option> */}
              <option value="8bit">8-bit</option>
              <option value="anime">Anime</option>
            </select>
          </div>
          {/* Theme selection radio buttons */}
          <div className="">
            {artStyle !== 'gta' && artStyle !== 'pixar' && (
              <div style={{ textAlign: 'center' }}>
                <label style={{ color: 'white' }}>
                  <input
                    type="radio"
                    value="normal" // Removed the space here
                    checked={theme === 'normal'}
                    onChange={e => setTheme(e.target.value.trim())} // Trim to be safe
                  />
                  Normal
                </label>
                <br />
                <label style={{ color: 'white' }}>
                  <input
                    type="radio"
                    value="medieval" // Removed the space here
                    checked={theme === 'medieval'}
                    onChange={e => setTheme(e.target.value.trim())} // Trim to be safe
                  />
                  Medieval 🏰
                </label>
                <br />
                <label style={{ color: 'white' }}>
                  <input
                    type="radio"
                    value="cyberpunk"
                    checked={theme === 'cyberpunk'}
                    onChange={e => setTheme(e.target.value)}
                  />
                  Cyberpunk 🦾
                </label>
                <br />
                <label style={{ color: 'white' }}>
                  <input
                    type="radio"
                    value="sports"
                    checked={theme === 'sports'}
                    onChange={e => setTheme(e.target.value)}
                  />
                  Sports 🏈
                </label>
              </div>
            )}
          </div>

          <div className="py-2">
            {artStyle === 'gta' && (
              <input
                type="text"
                placeholder="Enter the map location (optional) "
                value={location}
                onChange={e => setLocation(e.target.value)}
                className="text-lg text-center w-full p-2 my-2"
              />
            )}
          </div>

          <button
            className="cta-button text-2xl font-bold flex items-center justify-center mx-auto mt-2 px-4 rounded"
            onClick={generate}
          >
            Generate Avatar!
          </button>
        </>
      )}
      {dalleImageUrl && (
        <>
          {isGeneratedImageLoading && (
            <div className=" text-white text-center font-bold text-lg animate-pulse">
              Loading Generated Image... please wait
            </div>
          )}
          <img
            src={dalleImageUrl}
            alt="DALL-E Generated"
            className="mx-auto w-full max-w-xs rounded"
            onLoad={() => setGeneratedImageLoading(false)}
            onError={() => setGeneratedImageLoading(false)}
            style={{ display: isGeneratedImageLoading ? 'none' : 'block' }}
          />

          {isGeneratedImageLoading ? null : (
            <button
              className="cta-button text-2xl font-bold flex items-center justify-center mx-auto  p-2 rounded mt-8"
              onClick={() => window.open(dalleImageUrl, '_blank')}
            >
              Download !{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  fillRule="evenodd"
                  d="M5.478 5.559A1.5 1.5 0 016.912 4.5H9A.75.75 0 009 3H6.912a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H15a.75.75 0 000 1.5h2.088a1.5 1.5 0 011.434 1.059l2.213 7.191H17.89a3 3 0 00-2.684 1.658l-.256.513a1.5 1.5 0 01-1.342.829h-3.218a1.5 1.5 0 01-1.342-.83l-.256-.512a3 3 0 00-2.684-1.658H3.265l2.213-7.191z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M12 2.25a.75.75 0 01.75.75v6.44l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 011.06-1.06l1.72 1.72V3a.75.75 0 01.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}

          {isGeneratedImageLoading ? null : (
            <div>
              <button
                className=" bg-slate-800 text-white p-2 rounded mt-8 flex items-center justify-center mx-auto  "
                onClick={() => window.location.reload()}
              >
                Make more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 ml-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 013.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 10-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 00-4.392-4.392 49.422 49.422 0 00-7.436 0A4.756 4.756 0 003.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 101.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 013.01-3.01c1.19-.09 2.392-.135 3.605-.135zm-6.97 6.22a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 004.392 4.392 49.413 49.413 0 007.436 0 4.756 4.756 0 004.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 00-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 01-3.01 3.01 47.953 47.953 0 01-7.21 0 3.256 3.256 0 01-3.01-3.01 47.759 47.759 0 01-.1-1.759L6.97 15.53a.75.75 0 001.06-1.06l-3-3z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p className="text-sm text-gray-300 mt-2">
                If you are getting irrelevant results, it can be because the
                photo is:
                <br />
                - Explicit or can be considered as NSFW
                <br />
                - Not clear enough
                <br />
                - Face not visible
                <br />
                - Photo of a famous person
                <br />
                Try again with a different photo.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ImageMaker;
