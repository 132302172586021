/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Header from '../components/Header';

const PrivacyPolicy = () => {
  return (
    <div className="p-4 container mx-auto">
      <Header />
      <h1 className="text-xl font-bold mb-2">PRIVACY POLICY</h1>
      <p className="mb-1">Last updated December 06, 2023</p>
      <p className="mb-4">
        This privacy notice for AvatarFun ("we," "us," or "our"), describes how
        and why we might collect, store, use, and/or share ("process") your
        information when you use our services ("Services"), such as when you:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Visit our website at avatarfun.me, or any website of ours that links
          to this privacy notice
        </li>
        <li>
          Download and use our mobile application (Avatar Fun), or any other
          application of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <p className="mb-4">
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at
        sahir@fanfuel.social.
      </p>
      <h3 className=" text-xl font-bold mb-2">Handling of Google User Data</h3>
      <p>
        At AvatarFun.me, we respect your privacy and are committed to
        transparently handling your data. This section outlines our practices
        concerning data obtained through Google APIs.
      </p>
      <ul>
        <li>
          <strong>Data Collection via Google Auth:</strong> We use Google OAuth
          solely for the purpose of authenticating users and obtaining your
          email address. This is to assign quotas for image generation on our
          platform.
        </li>

        <li>
          <strong>Use of Google User Data:</strong> The email address collected
          through Google OAuth is used exclusively for managing your account and
          assigning usage quotas for our image generation service. We do not use
          this data for any other purpose.
        </li>

        <li>
          <strong>Data Storage and Security:</strong> Your email address, as
          obtained through Google OAuth, is securely stored and managed. We take
          all necessary measures to protect your data from unauthorized access,
          disclosure, alteration, or destruction.
        </li>

        <li>
          <strong>Data Sharing:</strong> We do not share, sell, rent, or trade
          your Google user data with third parties for their commercial
          purposes.
        </li>

        <li>
          <strong>Compliance with Google's User Data Policy:</strong> Our
          practices around the collection, use, storage, and sharing of Google
          user data are fully compliant with Google's API Services User Data
          Policy.
        </li>

        <li>
          <strong>Your Rights and Choices:</strong> You have control over your
          data. You can revoke our access to your data via Google at any time
          through your Google account settings.
        </li>

        <li>
          <strong>Changes to This Policy:</strong> If we modify the way we
          handle Google user data, we will update this section of our privacy
          policy and notify you as required by law.
        </li>
      </ul>

      <br />

      <h2 className="text-lg font-semibold mb-2">SUMMARY OF KEY POINTS</h2>
      <p className="mb-4">
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>
      {/* ... continued content ... */}
      <p className="mb-4">
        What personal information do we process? When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with us and the Services, the choices you make, and the
        products and features you use. Learn more about personal information you
        disclose to us.
      </p>
      <p className="mb-4">
        Do we process any sensitive personal information? We do not process
        sensitive personal information.
      </p>
      <p className="mb-4">
        Do we receive any information from third parties? We do not receive any
        information from third parties.
      </p>
      <p className="mb-4">
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about how we process your information.
      </p>
      <p className="mb-4">
        In what situations and with which parties do we share personal
        information? We may share information in specific situations and with
        specific third parties. Learn more about when and with whom we share
        your personal information.
      </p>
      <p className="mb-4">
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about how we keep your information safe.
      </p>
      <p className="mb-4">
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Learn more about your privacy rights.
      </p>
      <p className="mb-4">
        How do you exercise your rights? The easiest way to exercise your rights
        is by submitting a data subject access request, or by contacting us. We
        will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p className="mb-4">
        Want to learn more about what we do with any information we collect?
        Review the privacy notice in full.
      </p>

      <h2 className="text-lg font-semibold mb-2">
        1. WHAT INFORMATION DO WE COLLECT?
      </h2>
      <h3 className="font-medium mb-1">
        Personal information you disclose to us
      </h3>
      <p className="mb-4">
        In Short: We collect personal information that you provide to us.
        <br />
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
        <br />
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following:
        <br />
        - email addresses
        <br />
        - photo
        <br />
        Sensitive Information. We do not process sensitive information.
        <br />
        Payment Data. We may collect data necessary to process your payment if
        you make purchases, such as your payment instrument number, and the
        security code associated with your payment instrument. All payment data
        is stored by avatarfun. You may find their privacy notice link(s) here:
        __________.
        <br />
        Social Media Login Data. We may provide you with the option to register
        with us using your existing social media account details, like your
        Facebook, Twitter, or other social media account. If you choose to
        register in this way, we will collect the information described in the
        section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
        <br />
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h2 className="text-lg font-semibold mb-2">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </h2>
      <p className="mb-4">
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
        <br />
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
        <br />
        - To facilitate account creation and authentication and otherwise manage
        user accounts. We may process your information so you can create and log
        in to your account, as well as keep your account in working order.
        <br />- To save or protect an individual's vital interest. We may
        process your information when necessary to save or protect an
        individual’s vital interest, such as to prevent harm.
      </p>
      <h2 className="text-lg font-semibold mb-2">
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
      </h2>
      <p className="mb-4">
        In Short: We only process your personal information when we believe it
        is necessary and we have a valid legal reason (i.e., legal basis) to do
        so under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfill our contractual
        obligations, to protect your rights, or to fulfill our legitimate
        business interests.
        <br />
        If you are located in the EU or UK, this section applies to you.
        <br />
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
        <br />
        - Consent. We may process your information if you have given us
        permission (i.e., consent) to use your personal information for a
        specific purpose. You can withdraw your consent at any time. Learn more
        about withdrawing your consent.
        <br />
        - Legal Obligations. We may process your information where we believe it
        is necessary for compliance with our legal obligations, such as to
        cooperate with a law enforcement body or regulatory agency, exercise or
        defend our legal rights, or disclose your information as evidence in
        litigation in which we are involved.
        <br />- Vital Interests. We may process your information where we
        believe it is necessary to protect your vital interests or the vital
        interests of a third party, such as situations involving potential
        threats to the safety of any person.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
