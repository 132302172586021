// Import necessary libraries and styles
import './App.css';
// import Header from './components/Header';
// import ImageMaker from './components/ImageMaker';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import ImageMakerPage from './pages/ImageMakerPage';
import AvatarOption from './pages/AvatarOption';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
// import AuthHandler from './handler/AuthHandler';
// import ProtectedRoute from './handler/ProtectedRoute';
import Logout from './pages/Logout.jsx';
import HyperReal from './pages/HyperReal.jsx';

function App() {
  return (
    <Router>
      {/* <AuthHandler /> */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/imagemaker" element={<ImageMakerPage />} />
        <Route path="/avatartype" element={<AvatarOption />} />
        <Route path="/hyperreal" element={<HyperReal />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
}

export default App;

{
  /* <div className="App flex flex-col min-h-screen">
<Header />
<ImageMaker className="flex-grow" />
<footer className=" text-zinc-300 text-xs mt-40 pt-20">
  <a
    href="https://twitter.com/sahir2k"
    target="_blank"
    rel="noopener noreferrer"
  >
    contact
  </a>
</footer>
</div> */
}
