import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const AvatarOptions = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gradient-bg">
      <Header />
      <div className="flex flex-col md:flex-row items-center justify-center gap-8 p-4">
        {/* Card for Stylized Avatars */}

        <Link to="/imagemaker">
          <div className="transform transition duration-500 hover:scale-105">
            <div className=" w-auto h-48  md:h-96 bg-black rounded-lg overflow-hidden border-4 border-transparent hover:border-purple-600">
              <img
                src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/af3e6765-6ac8-4423-9302-61557ffbb203.jpg"
                alt="Stylized Avatar"
                className="w-full h-full object-cover transition duration-500"
              />
            </div>
            <h2 className="text-white text-2xl text-center mt-4">
              Stylized Avatars
            </h2>
            <p className="text-gray-400 text-base text-center">
              Disney, 8Bit, Anime, GTA etc
            </p>
          </div>
        </Link>

        {/* Card for Hyperreal Avatars */}
        <Link to="/hyperreal">
          <div className="transform transition duration-500 hover:scale-105">
            <div className="h-48 md:h-96 w-auto bg-black rounded-lg overflow-hidden border-4 border-transparent hover:border-purple-600">
              <video
                src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/hyperreal/final-bb.mp4"
                alt="Hyperreal Avatar"
                className="w-full h-full object-cover transition duration-500"
                controls={false}
                loop={true}
                muted={true}
                autoPlay={true}
              />
            </div>
            <h2 className="text-white text-2xl text-center mt-4">
              HyperReal Avatars
            </h2>
            <p className="text-gray-400 text-base text-center">
              Your Real Face! on High quality images and Boomerang videos
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AvatarOptions;
