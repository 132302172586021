// At the top of your component file, import useState and useEffect from React if needed
import React from 'react';
import logo from '../assets/logo.png';
// import combineddave from '../assets/header/combineddave.png';
// import combinedjane from '../assets/header/combinedjane.png';
import Login from './Login';
import { Link } from 'react-router-dom';

// The main landing page component
const LandingPage = () => {
  // const [showFirstImage, setShowFirstImage] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShowFirstImage(prev => !prev);
  //   }, 4000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="bg-black">
      <div className=" overflow-auto md:h-screen mx-auto container bg-black">
        {' '}
        {/* Full viewport height and overflow control */}
        <div className="flex flex-col md:flex-row h-full">
          {' '}
          {/* Ensure inner flex container fits within the height */}
          <div className="md:w-1/3 md:flex md:flex-col md:justify-center text-center">
            <main className="flex flex-col items-center">
              <div className="footer-bottom-cta mt-4 md:mt-10">
                {/* Replace with your actual SVG code or use an img tag with the SVG as the src */}
                <header className="p-8 pt-1">
                  <img src={logo} alt="logo" className="h-40 sm:h-40 mx-auto" />

                  <h1 className="text-xl mb-8">
                    Turn Your Selfies into Awesome Avatars
                  </h1>

                  {/* <Link to="/avatartype">
                    <button onClick={}  className="cta-button font-bold flex items-center text-2xl justify-center mx-auto  ">
                      Try for Free{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 ml-2"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </Link> */}

                  {/* <button className="cta-button font-bold flex items-center justify-center mx-auto  ">
                    Be Right Back - Maintainence ⚒️
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 ml-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button> */}

                  <Login />
                </header>
              </div>
            </main>
          </div>
          {/* image Gallery */}
          <div className="">
            <div
              className=" p-4 flex-grow overflow-auto mx-auto md:w-4/5"
              style={{
                backgroundImage:
                  'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #1a1a1a 30%, #1a1a1a 70%, rgba(255, 255, 255, 0) 100%)',
              }}
            >
              <div className="p-4 flex-grow overflow-auto relativeContainer ">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 ">
                  {/* First Column */}
                  <div className="flex flex-col space-y-6">
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/91754bad-8dc3-4415-8f09-7b2aa6e66c1e%20(1).jpg"
                      alt="Gallery 2"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/2b6a3535-4b48-4a7e-b7c0-ae7f97a95f95.jpg"
                      alt="Gallery 1"
                      className=" max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/jena.jpg"
                      alt="Gallery 3"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                  {/* Second Column with Margin Top */}
                  <div className="flex flex-col space-y-6 mt-8 md:mt-14">
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/troublemkers.jpg"
                      alt="Gallery 6"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />

                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/aysha.jpg"
                      alt="Gallery 4"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />

                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/628cfe9a-1911-4106-acbd-6fbda4d8bfb5%20(1).jpg"
                      alt="Gallery 5"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                  {/* Third Column */}
                  <div className=" flex-col hidden md:flex space-y-6">
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/aniime.jpg"
                      alt="Gallery 7"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/jiho-vice-compress.jpg"
                      alt="Gallery 8"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                    <img
                      src="https://jaoanycceyupsoiqwonf.supabase.co/storage/v1/object/public/8bitme/landingpage/an.jpg"
                      alt="Gallery 4"
                      className="max-w-xs h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer className=" text-zinc-300 text-center mx-auto py-2 mt-10 flex justify-around ">
              <a
                href="https://twitter.com/palmlabshq"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
              <Link to="/terms">Terms</Link>
              <Link to="/privacy-policy">Privacy policy</Link>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
// background: rgb(2, 0, 36);
// background: radial-gradient(circle, rgba(2,0,36,1) 69%, rgba(43,36,111,1) 100%, rgba(108,92,231,1) 100%, rgba(2,0,36,1) 100%, rgba(108,92,231,1) 100%);
