import React from 'react';

// eslint-disable-next-line react/prop-types
function Popup({ message, onClose }) {
  return (
    <div className="popup-backdrop">
      <div className="popup-content text-center bg-purple-950 text-white">
        <p>{message}</p>
        <button
          onClick={onClose}
          className=" p-2 px-4 mt-4 rounded-md bg-blue-950 font-bold"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default Popup;
