import Header from '../components/Header';
import ImageMaker from '../components/ImageMaker';
import { Link } from 'react-router-dom';

const ImageMakerPage = () => {
  return (
    <div className="gradient-bg pb-10 relative">
      <div className="App flex flex-col min-h-screen container mx-auto ">
        <Header />
        <ImageMaker className="flex-grow" />
        <footer className=" text-zinc-300 text-center mx-auto py-2 mt-10 flex  absolute bottom-10 ">
          <a
            href="https://twitter.com/sahir2k"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>

          <p className=" mx-2">|</p>
          <Link to="/terms">Terms </Link>
          <p className=" mx-2">|</p>
          <Link to="/privacy-policy">Privacy policy</Link>
        </footer>
      </div>
    </div>
  );
};

export default ImageMakerPage;
