import React from 'react';
import logo from '../assets/logo.png';
// import combineddave from '../assets/header/combineddave.png';
// import combinedjane from '../assets/header/combinedjane.png';

// import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  // const [showFirstImage, setShowFirstImage] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShowFirstImage(prev => !prev);
  //   }, 4000);
  //   return () => clearInterval(interval);
  // }, []);

  // const firstImageClassName = `mx-auto sm:w-1/3 mb-5 ${
  //   showFirstImage ? 'imageFadeIn' : 'imageFadeOut'
  // }`;
  // const secondImageClassName = `mx-auto sm:w-1/3 mb-5 ${
  //   showFirstImage ? 'imageFadeOut' : 'imageFadeIn'
  // }`;

  return (
    <div>
      <div className="App-header text-center sm:flex justify-center items-center sm:p-6">
        <Link to="/">
          <img src={logo} alt="logo" className="h-20 sm:h-40 mx-auto" />
        </Link>
        {/* {showFirstImage ? (
          <img
            src={combineddave}
            alt="Recent Generation 1"
            style={{
              borderRadius: '5px',
              opacity: 1,
              transition: 'opacity 2s',
            }}
            className="mx-auto sm:w-1/3 mb-5"
          />
        ) : (
          <img
            src={combinedjane}
            alt="Recent Generation 1"
            style={{
              borderRadius: '5px',
              opacity: 1,
              transition: 'opacity 2s',
            }}
            className="mx-auto sm:w-1/3 mb-5"
          />
        )} */}

        {/* <div className="alert-message">
          <p className="text-yellow-500 bg-black p-4">
            Limit for the day reached. Please try again tomorrow or follow{' '}
            <a href="https://twitter.com/sahir2k" style={{ color: 'white' }}>
              @sahir2k
            </a>{' '}
            to know once the site is back up !
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Header;
